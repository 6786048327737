import React, { Component, Fragment } from 'react';
import Media from "react-media";

import Item from './Item';

export default class MobileMenu extends Component {

  state = {

    category: null

  }

  render(){

    const { state: { category }, props: { data } } = this;

    const root_items = [
      <li key="home" className="Menu__item Menu__item--homepage">
        <a href="/">Home</a>
        {false && <div className="Menu__itemImage">
          <img src="/assets/logo.png" />
        </div>}
      </li>
    ]

    data.forEach((i) => {

      root_items.push(
        <Item currentCategory={category} key={i.id} {...i} showCategory={this.showCategory} closeCategory={this.closeCategory} />
      );

    });

    root_items.push(
      <Fragment>
        <li key="my" className="Menu__item Menu__item--link">
          <a href="/my-quinta">My Quinta</a>
        </li>

        <li key="chi-siamo" className="Menu__item Menu__item--link">
          <a href="/chi-siamo">Chi siamo</a>
        </li>

        <li key="pagamento" className="Menu__item Menu__item--link">
          <a href="/pagamenti">Metodi di pagamento</a>
        </li>

        <li key="spedizione" className="Menu__item Menu__item--link">
          <a href="/spedizioni">Spedizioni</a>
        </li>

        <li key="condizioni" className="Menu__item Menu__item--link" style={{ border: 0 }}>
          <a href="/condizioni">Condizioni acquisto usato</a>
        </li>

        <li key="informativa" className="Menu__item Menu__item--note">
          <a href="/privacy">Informativa sulla privacy</a>
        </li>

      </Fragment>
    )

    return (
      <Fragment>

        <div className="Menu">

            <Media query="(max-width: 799px)">
              {matches =>
                  matches ? ( // Less than 799px
                      <ul className="Menu__list">
                        {category ? this.categoryItems(category) : root_items}
                      </ul>
                    ) : (
                      <Fragment>
                        <div className="Menu__root">
                          <ul className="Menu__list">
                            {root_items}
                          </ul>
                          {category && (
                            <div className="Menu__detail">
                              <ul>
                                {this.categoryItems(category)}
                              </ul>
                            </div>
                          )}
                        </div>
                    </Fragment>
                )
              }
            </Media>

        </div>

      </Fragment>
    );

  }

  categoryItems = (id) => {

    const cat = this.props.data.find(d => d.id === id);

    const items = [
      <Media query="(max-width: 799px)">
        <li key="rootC" className="Menu__item Menu__item--second Menu__item--back" onClick={this.closeCategory}>
          <a>
            {`< ${cat.text}`}
          </a>
        </li>
      </Media>
    ];

    cat.children.forEach((i) => {

      if (i.active) {
        items.push(
          <li key={i.id} className="Menu__item Menu__item--second" onClick={(e) => this.openCategory(e, i.id)}>
            <a href={`/prodotti?category=${cat.id}>${i.id}`}>
              {i.text}
            </a>
          </li>
        );
      }

    });

    return items;

  }

  showCategory = (event, id) => {

    event.preventDefault();

    this.setState({ category: id });

  }

  closeCategory = (event) => {

    event.preventDefault();

    this.setState({ category: null });

  }

  openCategory = (event, id) => {

    //event.preventDefault();

    //window.location.href = `/categoria/${id}`;

  }

}
